// extracted by mini-css-extract-plugin
export var SliderWidth = "Projects-module--SliderWidth--69cb8";
export var arrowImg = "Projects-module--arrowImg--f1395";
export var bottomContent = "Projects-module--bottomContent--8600f";
export var bottomHead = "Projects-module--bottomHead--bfd3b";
export var bottomPort = "Projects-module--bottomPort--28ef2";
export var bottomPort2 = "Projects-module--bottomPort2--f5ef2";
export var bottomTags = "Projects-module--bottomTags--9a5f4";
export var bottomhead1 = "Projects-module--bottomhead1--1eb7c";
export var bottomtag1 = "Projects-module--bottomtag1--23e5c";
export var bottomtag2 = "Projects-module--bottomtag2--1c329";
export var bottomtag3 = "Projects-module--bottomtag3--fb410";
export var bottomtag3Hover = "Projects-module--bottomtag3Hover--3ff7b";
export var cardWrapper = "Projects-module--cardWrapper--ef55e";
export var cards = "Projects-module--cards--123fb";
export var description = "Projects-module--description--a9d5d";
export var heading = "Projects-module--heading--3acb5";
export var headingPremium = "Projects-module--headingPremium--c48de";
export var mainWorkProjectHireCTO = "Projects-module--mainWorkProjectHireCTO--9c8b9";
export var mainWorkProjectsWebServices = "Projects-module--mainWorkProjectsWebServices--5e29f";
export var portfolioArrowIcon = "Projects-module--portfolioArrowIcon--f2ab0";
export var portfolioArrowIconCover = "Projects-module--portfolioArrowIconCover--cbe55";
export var portfolioArrowRightIconCover = "Projects-module--portfolioArrowRightIconCover--e8926";
export var portfolioLinkImage = "Projects-module--portfolioLinkImage--c1cbc";
export var premiumImg = "Projects-module--premiumImg--7b955";
export var project2 = "Projects-module--project2--83793";
export var projectsAll = "Projects-module--projectsAll--edba8";
export var providingImg = "Projects-module--providingImg--79c70";
export var reactJsIconContainer = "Projects-module--reactJsIconContainer--f54f5";
export var reactJsIconContainerLeft = "Projects-module--reactJsIconContainerLeft--1bdd6";
export var teamButton = "Projects-module--teamButton--e8909";
export var trailBg = "Projects-module--trailBg--e36a3";