// extracted by mini-css-extract-plugin
export var bottomContent = "ProjectsMobile-module--bottomContent--bce40";
export var bottomHead = "ProjectsMobile-module--bottomHead--fece0";
export var bottomPort = "ProjectsMobile-module--bottomPort--0dd40";
export var bottomPort2 = "ProjectsMobile-module--bottomPort2--c417c";
export var bottomTags = "ProjectsMobile-module--bottomTags--04376";
export var bottomhead1 = "ProjectsMobile-module--bottomhead1--25714";
export var bottomtag1 = "ProjectsMobile-module--bottomtag1--cc929";
export var bottomtag2 = "ProjectsMobile-module--bottomtag2--e6998";
export var bottomtag3 = "ProjectsMobile-module--bottomtag3--e69d2";
export var bottomtag3Hover = "ProjectsMobile-module--bottomtag3Hover--7aba3";
export var cardWrapper = "ProjectsMobile-module--cardWrapper--62d28";
export var caseStudiesBtnPortfolio = "ProjectsMobile-module--caseStudiesBtnPortfolio--5af32";
export var description = "ProjectsMobile-module--description--7494d";
export var firstMobileImagaPort = "ProjectsMobile-module--firstMobileImagaPort--60b15";
export var firstPortBottom = "ProjectsMobile-module--firstPortBottom--a8363";
export var firstPortFolio = "ProjectsMobile-module--firstPortFolio--4ff41";
export var head = "ProjectsMobile-module--head--67bc7";
export var heading = "ProjectsMobile-module--heading--a7389";
export var hoverFirstPortImg = "ProjectsMobile-module--hoverFirstPortImg--bae20";
export var normalrFirstPortImg = "ProjectsMobile-module--normalrFirstPortImg--2dbd7";
export var portImage = "ProjectsMobile-module--portImage--7fb5a";
export var portfolioLinkImage = "ProjectsMobile-module--portfolioLinkImage--d390b";
export var project2 = "ProjectsMobile-module--project2--67bba";
export var projectsAll = "ProjectsMobile-module--projectsAll--e7e06";
export var projectsMobileWeb = "ProjectsMobile-module--projectsMobileWeb--9e63f";
export var tag1 = "ProjectsMobile-module--tag1--04d3a";
export var tag2 = "ProjectsMobile-module--tag2--f567b";
export var tag3 = "ProjectsMobile-module--tag3--fbb21";
export var tags = "ProjectsMobile-module--tags--ec2b1";